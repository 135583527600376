import { useState, useEffect, useCallback } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import dayjs from 'dayjs'
import { Fetch } from '@/utils/Fetch'

import { isFieldTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'

import {
  CustomerContractFormType,
  CustomerContractSubmitBodyType,
  CustomerContractType,
  CustomerContractFormStateReturn,
  ContractInviteeType,
} from '../types'
import { ContractSettingType } from '../../ContractSetting/types'
import { inputTypeOptions } from '../../DocumentField/types'
import { answerTypeOptions } from '../../QuestionItem/types'

type ContractInviteesBaseType = Omit<ContractInviteeType[], 'documentFields'>

type SoftContractSettingType = {
  id: string
  // name?: string
  // status?: ContractSettingType['status']
  // customerInviteeNum?: number
  // draftDocuments?: ContractSettingType['draftDocuments']
  questionItems: ContractSettingType['questionItems']
  // approverSettings?: ContractSettingType['approverSettings']
}

export {
  CustomerContractFormType,
  CustomerContractSubmitBodyType,
  CustomerContractType,
  ContractInviteesBaseType,
  SoftContractSettingType,
}

export const convertInputTypeToAnswerType = (
  from: typeof inputTypeOptions[number]['value'],
): typeof answerTypeOptions[number]['value'] => {
  return `${from}_field`
}

const defaultWidth = (inputType: typeof inputTypeOptions[number]['value']) => {
  switch (inputType) {
    case 'textarea':
      return 360
    case 'signature':
    case 'signature_date':
      return 120
    case 'stamp':
      return 60
    case 'checkbox':
      return 12
    default:
      return 180
  }
}

const defaultHeight = (inputType: typeof inputTypeOptions[number]['value']) => {
  switch (inputType) {
    case 'textarea':
      return 120
    case 'stamp':
      return 60
    case 'checkbox':
      return 12
    default:
      return 24
  }
}

export const useCustomerContractForm = (id = '', contractSetting?: SoftContractSettingType, folderId?: string) => {
  const [documentsState, setDocuments] = useState<CustomerContractType['documents']>([])
  const [signedDocumentsState, setSignedDocuments] = useState<CustomerContractType['signedDocuments']>([])
  const [contractInviteesState, setContractInvitees] = useState<ContractInviteesBaseType>([])
  const {
    register: customerContractRegister,
    control: customerContractControl,
    handleSubmit: handleCustomerContractSubmit,
    watch,
    formState: {
      dirtyFields: customerContractDirtyFields,
      isDirty: isCustomerContractFormDirty,
      isSubmitting: isSubmittingCustomerContract,
      errors: customerContractErrors,
      isValid: isCustomerContractValid,
    },
    setValue: setCustomerContractValue,
    getFieldState: getCustomerContractFieldState,
    reset: resetCustomerContractForm,
  } = useForm<CustomerContractFormType>()
  const customerContract = convertFormToData(
    watch(),
    contractSetting?.questionItems || [],
    id,
    documentsState,
    signedDocumentsState,
    contractInviteesState,
  )

  const submitCustomerContract = handleCustomerContractSubmit(async (data: CustomerContractFormType) => {
    const path = `/api/v1/customer_contracts/${id}`
    const method = id ? 'PATCH' : 'POST'
    const body: CustomerContractSubmitBodyType = createSubmitBodyFromForm(
      data,
      customerContractDirtyFields,
      id,
      contractSetting,
      folderId,
    )
    const res = await Fetch(path, method, body)
    if (res.ok) {
      if (id) {
        refreshCustomerContract()
      } else {
        const json = await res.json()
        window.location.href = `/customer_contracts/${json.id}`
      }
    } else {
      const json = await res.json()
      const errorMessage = json.errors?.join('\n')
      alert(errorMessage || '保存に失敗しました')
    }
  })

  const {
    fields: documentFieldFields,
    append: plainAppendDocumentField,
    remove: plainRemoveDocumentField,
  } = useFieldArray({
    name: 'documentFields',
    keyName: 'key',
    control: customerContractControl,
  })
  const documentFields = documentFieldFields.map((field, index) => {
    return {
      ...field,
      ...watch().documentFields[index],
    }
  })
  const appendDocumentField = (
    documentId: string,
    inputTypeOption: typeof inputTypeOptions[number] = inputTypeOptions[0],
    documentPage?: number,
  ) => {
    const newFieldKey = new Date().getTime().toString()
    plainAppendDocumentField({
      key: newFieldKey,
      id: '',
      contractInviteeOption: {
        value: contractInviteesState[0].id,
        label: `${contractInviteesState[0].inviteeLastName}${contractInviteesState[0].inviteeFirstName}`,
      },
      documentId,
      inputTypeOption,
      isRequired: true,
    })
    if (documentPage || documentPage === 0) {
      appendFieldLocation({
        fieldKey: newFieldKey,
        page: documentPage,
        xCoord: 0,
        yCoord: 0,
      })
    }
  }
  const removeDocumentField = (index?: number | number[] | undefined) => {
    const removedFieldKeys: (string | number)[] = []
    if (typeof index === 'number') {
      removedFieldKeys.push(documentFields[index].key)
    } else if (typeof index === 'object') {
      removedFieldKeys.push(...index.map((itemIndex) => documentFields[itemIndex].key))
    }
    plainRemoveDocumentField(index)
    const linkedLocIndex: number[] = fieldLocations.flatMap((loc, i) =>
      removedFieldKeys.includes(loc.fieldKey) ? i : [],
    )
    removeFieldLocation(linkedLocIndex)
  }

  const {
    fields: fieldLocationFields,
    append: appendFieldLocation,
    remove: removeFieldLocation,
  } = useFieldArray({
    name: 'fieldLocations',
    control: customerContractControl,
  })
  const fieldLocations = fieldLocationFields.map((field, index) => {
    return {
      ...field,
      ...watch().fieldLocations[index],
    }
  })

  const {
    fields: customerFields,
    append: appendCustomer,
    remove: removeCustomer,
    replace: replaceCustomers,
  } = useFieldArray({
    name: 'customers',
    control: customerContractControl,
  })
  const customers = customerFields.map((field, index) => {
    return {
      ...field,
      ...watch().customers[index],
    }
  })

  const refreshCustomerContract = () => {
    if (!id) console.error(`id: '${id}'; id is not nullable to refresh Customer Contract`)
    ;(async () => {
      const res = await Fetch(`/api/v1/customer_contracts/${id}`, 'GET', {})
      const data: CustomerContractType = await res.json()
      const forms = convertDataToForm(data, contractSetting?.questionItems)
      resetCustomerContractForm(forms)
      setDocuments(data.documents)
      setSignedDocuments(data.signedDocuments)
      setContractInvitees(data?.contractInvitees || [])
    })()
  }
  const deleteCustomerContract = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (customerContract.status === 'pending' || customerContract.status === 'completed') return
      ;(async () => {
        if (window.confirm('契約書を削除してよろしいですか？')) {
          const res = await Fetch(`/api/v1/customer_contracts/${id}`, 'DELETE', {})
          if (res.ok) {
            alert(`契約書「${customerContract.name}」が削除されました`)
            window.location.href = '/'
          } else {
            const json = await res.json()
            const errorMessage = json.errors?.join('\n')
            alert(errorMessage || '契約書の削除に失敗しました')
            refreshCustomerContract()
          }
        }
      })()
    },
    [customerContract],
  )

  useEffect(() => {
    if (id) refreshCustomerContract()
  }, [id])

  return {
    customerContract,
    customerContractRegister,
    customerContractControl,
    submitCustomerContract,
    handleCustomerContractSubmit,
    refreshCustomerContract,
    setCustomerContractValue,
    getCustomerContractFieldState,
    customerContractDirtyFields,
    isCustomerContractFormDirty,
    isSubmittingCustomerContract,
    isCustomerContractValid,
    documentFields,
    appendDocumentField,
    removeDocumentField,
    fieldLocations,
    appendFieldLocation,
    removeFieldLocation,
    customers,
    appendCustomer,
    removeCustomer,
    replaceCustomers,
    resetCustomerContractForm,
    customerContractErrors,
    deleteCustomerContract,
  }
}

export const convertDataToForm = (
  data: CustomerContractType,
  questionItems: ContractSettingType['questionItems'] = [],
) => {
  const answerItems: CustomerContractFormType['answerItems'] = {}
  if (data.answerItems) {
    questionItems.forEach((questionItem) => {
      answerItems[questionItem.id] = {
        ...data.answerItems[questionItem.id],
        selectedOption: data.answerItems[questionItem.id]?.value
          ? {
              value: data.answerItems[questionItem.id].value.toString(),
              label: data.answerItems[questionItem.id].value.toString(),
            }
          : undefined,
        checkedOptions:
          questionItem.selectItemsText?.split(',').map((selectItem) => ({
            value: selectItem,
            isChecked: data.answerItems[questionItem.id]?.value?.toString().split(',').includes(selectItem),
          })) || [],
        byCustomer: data.answerItems[questionItem.id]?.byCustomer,
      }
      if (questionItem.answerType === 'date')
        answerItems[questionItem.id].value = data.answerItems[questionItem.id]?.value
          ? dayjs(data.answerItems[questionItem.id].value).format('YYYY-MM-DD')
          : ''
    })
  }
  const documentFields: (CustomerContractFormType['documentFields'][number] & {
    fieldLocations: Omit<CustomerContractFormType['fieldLocations'][number], 'fieldKey'>[]
  })[] =
    data.contractInvitees?.flatMap((invitee) =>
      invitee.documentFields.map((field) => ({
        ...field,
        key: field.id,
        contractInviteeOption: { value: invitee.id, label: `${invitee.inviteeLastName}${invitee.inviteeFirstName}` },
        inputTypeOption: inputTypeOptions.find((opt) => opt.value === field.inputType) || inputTypeOptions[0],
      })),
    ) || []
  const fieldLocations: CustomerContractFormType['fieldLocations'] = documentFields.flatMap((field) =>
    field.fieldLocations.map((loc) => ({
      ...loc,
      fieldKey: field.key,
    })),
  )
  const customerContractForm: CustomerContractFormType = {
    ...data,
    status: data.status || 'draft',
    folderOption: data.folder && {
      value: data.folder.id,
      label: data.folder.name,
    },
    additionalDocuments: [],
    approvers:
      data.contractInvitees
        ?.filter((invitee) => invitee.inviteeType === 'User')
        .map((invitee) => ({
          value: invitee.inviteeId,
          label: `${invitee.inviteeLastName}${invitee.inviteeFirstName}`,
        })) || [],
    customers:
      data.contractInvitees
        ?.filter((invitee) => invitee.inviteeType === 'Customer')
        .map((invitee) => ({
          id: invitee.inviteeId,
          email: invitee.inviteeEmail,
          lastName: invitee.inviteeLastName,
          firstName: invitee.inviteeFirstName,
          lastNameKana: invitee.inviteeLastNameKana,
          firstNameKana: invitee.inviteeFirstNameKana,
          weddingDate: invitee.inviteeWeddingDate,
        })) || [],
    answerItems,
    documentFields,
    fieldLocations,
  }
  return customerContractForm
}

const convertFormToData = (
  form: CustomerContractFormType,
  questionItems: ContractSettingType['questionItems'],
  id = '',
  documents: CustomerContractType['documents'] = [],
  signedDocuments: CustomerContractType['signedDocuments'] = [],
  contractInviteesBase: ContractInviteesBaseType = [],
) => {
  const answerItems: CustomerContractType['answerItems'] = {}
  questionItems.forEach((questionItem) => {
    if (!form.answerItems) return

    answerItems[questionItem.id] = {
      ...form.answerItems[questionItem.id],
    }
    if (questionItem.answerType === 'single_select') {
      answerItems[questionItem.id].value = form.answerItems[questionItem.id]?.selectedOption?.value || ''
    }
    if (questionItem.answerType === 'multi_select') {
      answerItems[questionItem.id].value = form.answerItems[questionItem.id]?.checkedOptions
        ?.filter((opt) => opt.isChecked)
        .map((opt) => opt.value)
        .join(',')
    }
  })
  const contractInvitees: CustomerContractType['contractInvitees'] = contractInviteesBase.map((invitee) => ({
    ...invitee,
    documentFields: form.documentFields
      .filter((field) => field.contractInviteeOption.value === invitee.id)
      .map((field) => ({
        ...field,
        inputType: field.inputTypeOption.value,
        fieldLocations: form.fieldLocations.filter((loc) => loc.fieldKey === field.key),
      })),
  }))
  const customerContractData: CustomerContractType = {
    ...form,
    id,
    folder: form.folderOption && {
      id: form.folderOption.value,
      name: form.folderOption.label,
    },
    documents,
    signedDocuments,
    answerItems,
    contractInvitees,
  }
  return customerContractData
}

export const createSubmitBodyFromForm = (
  form: CustomerContractFormType,
  dirtyFields: CustomerContractFormStateReturn['dirtyFields'],
  id = '',
  contractSetting?: SoftContractSettingType,
  folderId?: string,
) => {
  const submitBody: CustomerContractSubmitBodyType =
    form.additionalDocuments?.length > 0 ? new FormData() : { customer_contract: { answer_item_forms: [] } }
  if (submitBody instanceof FormData) {
    if (!id && contractSetting) submitBody.append('customer_contract[contract_setting_id]', contractSetting.id)
    Array.from(form.additionalDocuments).forEach((doc) => {
      submitBody.append('customer_contract[documents][]', doc)
    })
    if (dirtyFields.name) submitBody.append('customer_contract[name]', form.name)
    if (typeof dirtyFields.folderOption === 'object' ? dirtyFields.folderOption?.value : dirtyFields.folderOption)
      submitBody.append('customer_contract[folder_id]', form.folderOption?.value || '') // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
    if (folderId) submitBody.append('customer_contract[folder_id]', folderId)
  } else {
    if (!id && contractSetting) submitBody.customer_contract.contract_setting_id = contractSetting.id
    if (typeof dirtyFields.folderOption === 'object' ? dirtyFields.folderOption?.value : dirtyFields.folderOption)
      submitBody.customer_contract.folder_id = form.folderOption?.value // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
    if (folderId) submitBody.customer_contract.folder_id = folderId
    if (dirtyFields.name) submitBody.customer_contract.name = form.name
    if (
      typeof dirtyFields.approvers === 'object'
        ? dirtyFields.approvers?.some((approver) => approver.value)
        : dirtyFields.approvers // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
    )
      submitBody.customer_contract.approver_forms = form.approvers.map((approver) => approver.value)
    if (
      dirtyFields.customers?.some(
        (customer) =>
          customer.email ||
          customer.lastName ||
          customer.lastNameKana ||
          customer.firstName ||
          customer.firstNameKana ||
          customer.weddingDate,
      )
    ) {
      submitBody.customer_contract.customer_forms = {}
      form.customers.forEach((customer, index) => {
        if (submitBody.customer_contract.customer_forms) {
          submitBody.customer_contract.customer_forms[`${index}`] = {
            // customer_id: customer.id,
            email: customer.email,
            last_name: customer.lastName,
            first_name: customer.firstName,
            last_name_kana: customer.lastNameKana,
            first_name_kana: customer.firstNameKana,
            wedding_date: customer.weddingDate,
          }
        }
      })
    }
    contractSetting?.questionItems.forEach((questionItem) => {
      if (!dirtyFields.answerItems) return
      switch (questionItem.answerType) {
        case 'single_select': {
          if (
            typeof dirtyFields.answerItems[questionItem.id]?.byCustomer === 'boolean' ||
            (typeof dirtyFields.answerItems[questionItem.id]?.selectedOption === 'object' // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
              ? dirtyFields.answerItems[questionItem.id]?.selectedOption?.value
              : dirtyFields.answerItems[questionItem.id]?.selectedOption)
          ) {
            submitBody.customer_contract.answer_item_forms.push({
              question_item_id: questionItem.id,
              value: form.answerItems[questionItem.id].selectedOption?.value || '',
              by_customer: form.answerItems[questionItem.id].byCustomer,
            })
          }
          break
        }
        case 'multi_select': {
          if (
            typeof dirtyFields.answerItems[questionItem.id]?.byCustomer === 'boolean' ||
            dirtyFields.answerItems[questionItem.id]?.checkedOptions?.some((opt) => opt.isChecked)
          ) {
            submitBody.customer_contract.answer_item_forms.push({
              question_item_id: questionItem.id,
              value: form.answerItems[questionItem.id].checkedOptions
                ?.filter((opt) => opt.isChecked)
                .map((opt) => opt.value)
                .join(','),
              by_customer: form.answerItems[questionItem.id].byCustomer,
            })
          }
          break
        }
        case 'text':
        case 'textarea':
        case 'date':
        case 'time':
        case 'number': {
          if (
            typeof dirtyFields.answerItems[questionItem.id]?.byCustomer === 'boolean' ||
            dirtyFields.answerItems[questionItem.id]?.value ||
            dirtyFields.answerItems[questionItem.id]?.subvalue
          ) {
            submitBody.customer_contract.answer_item_forms.push({
              question_item_id: questionItem.id,
              value: form.answerItems[questionItem.id].value?.toString() || '',
              subvalue: form.answerItems[questionItem.id].subvalue,
              by_customer: form.answerItems[questionItem.id].byCustomer,
            })
          }
          break
        }
        case 'email':
        case 'name':
        case 'name_kana': {
          if (
            typeof dirtyFields.answerItems[questionItem.id]?.byCustomer === 'boolean' ||
            dirtyFields.answerItems[questionItem.id]?.value ||
            dirtyFields.answerItems[questionItem.id]?.subvalue ||
            typeof questionItem.customerOrder === 'number'
          ) {
            submitBody.customer_contract.answer_item_forms.push({
              question_item_id: questionItem.id,
              value: form.answerItems[questionItem.id].value?.toString() || '',
              subvalue: form.answerItems[questionItem.id].subvalue,
              by_customer: form.answerItems[questionItem.id].byCustomer,
            })
          }
          break
        }
        default:
          return
      }
    })
    if (
      dirtyFields.documentFields?.some(
        (field) =>
          (typeof field.contractInviteeOption === 'object'
            ? field.contractInviteeOption?.value
            : field.contractInviteeOption) || // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
          field.documentId ||
          (typeof field.inputTypeOption === 'object' ? field.inputTypeOption?.value : field.inputTypeOption) || // Selectだと〇〇Optionのdirty stateに直接booleanが入るため
          field.isRequired,
      ) ||
      dirtyFields.fieldLocations?.some((loc) => loc.page || loc.xCoord || loc.yCoord)
    ) {
      submitBody.customer_contract.document_field_forms = form.documentFields.map((field) => ({
        id: field.id,
        contract_invitee_id: field.contractInviteeOption.value,
        original_document_id: field.documentId,
        input_type: field.inputTypeOption.value,
        is_required: field.isRequired || ['signature', 'signature_date', 'stamp'].includes(field.inputTypeOption.value),
        width: field.width || defaultWidth(field.inputTypeOption.value),
        height: field.height || defaultHeight(field.inputTypeOption.value),
        field_location_forms: dirtyFields.fieldLocations?.some((loc) => loc.page || loc.xCoord || loc.yCoord)
          ? form.fieldLocations
              .filter((loc) => loc.fieldKey === field.key)
              .map((loc) => ({
                page: loc.page,
                x_coord: loc.xCoord,
                y_coord: loc.yCoord,
              }))
          : undefined,
      }))
    }
  }
  return submitBody
}

export const isAnyAnswerItemErrorExceptBlank = (
  answerItemErrors: CustomerContractFormStateReturn['errors']['answerItems'],
) => {
  if (!answerItemErrors) return false
  return Object.values(answerItemErrors).some(
    (error) =>
      (error?.value?.type && error.value.type !== 'required') ||
      (error?.subvalue?.type && error.subvalue.type !== 'required') ||
      (error?.selectedOption?.type && error.selectedOption.type !== 'required') ||
      (error?.checkedOptions?.[0]?.isChecked && error.checkedOptions[0].isChecked.type !== 'atLeastOne'), // AnswerItemFormsの実装に依存している
  )
}

export const isAllRequiredItemAnswered = (
  questionItems: ContractSettingType['questionItems'],
  answerItems: CustomerContractType['answerItems'],
) => {
  return questionItems.every((questionItem) => {
    if (isFieldTypeQuestion(questionItem.answerType)) return true
    if (!questionItem.isRequired) return true
    if (questionItem.isRequired) {
      switch (questionItem.answerType) {
        case 'single_select':
        case 'multi_select':
        case 'text':
        case 'textarea':
        case 'date':
        case 'time':
        case 'email':
          return !!answerItems[questionItem.id]?.value
        case 'name':
        case 'name_kana':
          return !!answerItems[questionItem.id]?.value && !!answerItems[questionItem.id].subvalue
        case 'number':
          return !!answerItems[questionItem.id]?.value || answerItems[questionItem.id]?.value === 0
        default:
          return true
      }
    }
    return true
  })
}
