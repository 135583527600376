import React, { useState, useEffect, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { Fetch } from '@/utils/Fetch'

import { CustomerContractsNewPropsType, stepOptions } from './types'

import { toCamelCaseObjectFromSnakeCase } from '@/utils/toCamelCaseObjectFromSnakeCase'
import { TopStepperLayout } from '@/components/layouts'
import { StepType } from '@/components/layouts/components/Sidebar/types'
import { BasicInfo, CustomerForms } from '@/components/features/CustomerContract/components'
import { ContractSettingOptions } from '@/components/features/CustomerContract/types'
import { useCustomerContractForm } from '@/components/features/CustomerContract/hooks/useCustomerContractForm'
import { isCustomerTypeQuestion } from '@/components/features/ContractSetting/hooks/useContractSettingForm'

const CustomerContractNew: React.FC<CustomerContractsNewPropsType> = ({ contractSetting, ...props }) => {
  const {
    customerContract,
    customerContractRegister,
    customerContractControl,
    submitCustomerContract,
    isCustomerContractFormDirty,
    isSubmittingCustomerContract,
    refreshCustomerContract,
    setCustomerContractValue,
    resetCustomerContractForm,
    customerContractErrors,
  } = useCustomerContractForm('', contractSetting, props.folderId)
  const [steps, setSteps] = useState<StepType[]>(stepOptions.map((opt, i) => ({ ...opt, isCurrent: i === 0 })))
  const [contractSettingOptions, setContractSettingOptions] = useState<ContractSettingOptions>([])

  const isDone = (key: string) => {
    switch (key) {
      case 'basic_info':
        return !!contractSetting
      default:
        return false
    }
  }
  const isDisabled = (key: string) => {
    switch (key) {
      case 'basic_info':
        return false
      case 'customer_forms':
        return !contractSetting?.questionItems.some((questionItem) => isCustomerTypeQuestion(questionItem.answerType))
      default:
        return true
    }
  }

  const getContractSettingOptions = async () => {
    const res = await Fetch('/api/v1/contract_settings?status=active&only_for_new_contract=true&per=1000', 'GET', {})
    const json = await res.json()
    const contractSettings = toCamelCaseObjectFromSnakeCase<{ contractSettings: ContractSettingOptions }>(
      json,
    ).contractSettings
    setContractSettingOptions(
      !props.folderId ? contractSettings : contractSettings.filter((set) => set.folder?.id === props.folderId),
    )
  }
  const currentStep = useMemo(() => steps.find((step) => step.isCurrent), [steps])

  useEffect(() => {
    if (contractSetting?.id) {
      setCustomerContractValue('name', contractSetting.name, { shouldDirty: true })
      contractSetting.questionItems.forEach((questionItem) => {
        setCustomerContractValue(`answerItems.${questionItem.id}.byCustomer`, questionItem.initiallyByCustomer, {
          shouldDirty: true,
        })
      })
    } else {
      getContractSettingOptions()
    }
  }, [currentStep])

  return (
    <TopStepperLayout
      title="契約書"
      steps={steps.map((step) => ({
        ...step,
        isDone: isDone(step.value),
        isDisabled: isDisabled(step.value),
        isSkipped: !contractSetting?.id && step.value === 'answer_item_forms',
      }))}
      setCurrentStep={setSteps}
      afterStepChange={() => resetCustomerContractForm()}
    >
      {(() => {
        switch (currentStep?.value) {
          case 'basic_info':
            return (
              <>
                <BasicInfo
                  {...props}
                  customerContract={{
                    ...customerContract,
                    status: customerContract.status || 'draft',
                    documents: customerContract.documents || [],
                    contractInvitees: customerContract.contractInvitees || [],
                  }}
                  customerContractRegister={customerContractRegister}
                  customerContractControl={customerContractControl}
                  submitCustomerContract={submitCustomerContract}
                  isCustomerContractFormDirty={isCustomerContractFormDirty}
                  isSubmittingCustomerContract={isSubmittingCustomerContract}
                  refreshCustomerContract={refreshCustomerContract}
                  contractSettingOptions={contractSettingOptions}
                />
              </>
            )
          case 'customer_forms':
            return (
              <CustomerForms
                contractSetting={contractSetting}
                customerContract={{ ...customerContract, status: customerContract.status || 'draft' }}
                customerContractRegister={customerContractRegister}
                customerContractControl={customerContractControl}
                submitCustomerContract={submitCustomerContract}
                setCustomerContractValue={setCustomerContractValue}
                isCustomerContractFormDirty={isCustomerContractFormDirty}
                isSubmittingCustomerContract={isSubmittingCustomerContract}
                customerContractErrors={customerContractErrors}
              />
            )
          default:
            return <></>
        }
      })()}
    </TopStepperLayout>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('customer-contracts-new')
  if (node) {
    const data = node.dataset
    if (!data.props) return
    const props = toCamelCaseObjectFromSnakeCase<CustomerContractsNewPropsType>(JSON.parse(data.props))
    const formedCustomerContractNew = createRoot(node)
    formedCustomerContractNew.render(<CustomerContractNew {...props} />)
  }
})
