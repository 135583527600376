import {
  UseFormRegister,
  Control,
  UseFormSetValue,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  FieldErrorsImpl,
  UseFieldArrayReplace,
  UseFormStateReturn,
} from 'react-hook-form'
import { QuestionItemType } from '../QuestionItem/types'
import { DocumentType } from '../Document/types'
import { inputTypeOptions } from '../DocumentField/types'

export type BasicInfoPropsType = {
  folderId?: string
  contractSetting?: {
    draftDocuments?: DocumentType[]
    questionItems: QuestionItemType[]
  }
  customerContract?: {
    readonly id: string
    name: string
    status: typeof statusOptions[number]['value']
    documents: (DocumentType & { draftDocumentId?: string })[]
    answerItems: CustomerContractType['answerItems']
    contractInvitees: CustomerContractType['contractInvitees']
  }
  contractSettingOptions?: ContractSettingOptions
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
  submitCustomerContract: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  isCustomerContractFormDirty: boolean
  isSubmittingCustomerContract: boolean
  refreshCustomerContract?: () => void
}

export type ContractSettingOptions = {
  id: string
  name: string
  folder?: {
    id: string
    name: string
  }
}[]

export type CustomerFormsPropsType = {
  contractSetting?: {
    name: string
    customerInviteeNum: number
    questionItems: QuestionItemType[]
  }
  customerContract?: {
    readonly id: string
    name: string
    status: typeof statusOptions[number]['value']
    answerItems: CustomerContractType['answerItems']
  }
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
  submitCustomerContract: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  setCustomerContractValue: UseFormSetValue<CustomerContractFormType>
  isCustomerContractFormDirty: boolean
  isSubmittingCustomerContract: boolean
  customerContractErrors: FieldErrorsImpl<CustomerContractFormType>
  customers?: CustomerContractFormType['customers']
  appendCustomer?: UseFieldArrayAppend<CustomerContractFormType, 'customers'>
  removeCustomer?: UseFieldArrayRemove
  replaceCustomers?: UseFieldArrayReplace<CustomerContractFormType, 'customers'>
}

export type AnswerItemFormsPropsType = {
  contractSetting?: {
    questionItems: QuestionItemType[]
  }
  customerContract?: {
    name: string
    status: typeof statusOptions[number]['value']
  }
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
  submitCustomerContract: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  isCustomerContractFormDirty: boolean
  isSubmittingCustomerContract: boolean
}

export type DocumentFieldFormsPropsType = {
  contractSetting?: {
    draftDocuments: DocumentType[]
    questionItems: QuestionItemType[]
  }
  customerContract: {
    readonly id: string
    name: string
    status: typeof statusOptions[number]['value']
    documents: DocumentType[]
    answerItems: CustomerContractType['answerItems']
    contractInvitees: CustomerContractType['contractInvitees']
  }
  customerContractRegister: UseFormRegister<CustomerContractFormType>
  customerContractControl: Control<CustomerContractFormType>
  submitCustomerContract: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  isCustomerContractFormDirty: boolean
  isSubmittingCustomerContract: boolean
  setCustomerContractValue: UseFormSetValue<CustomerContractFormType>
  documentFields: CustomerContractFormType['documentFields']
  appendDocumentField: (documentId: string, inputTypeOption?: typeof inputTypeOptions[number]) => void
  removeDocumentField: UseFieldArrayRemove
  fieldLocations: CustomerContractFormType['fieldLocations']
  appendFieldLocation: UseFieldArrayAppend<CustomerContractFormType, 'fieldLocations'>
  removeFieldLocation: UseFieldArrayRemove
  refreshCustomerContract: () => void
}

export type AskCustomerToAnswerModalPropsType = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  customerContract: {
    readonly id: string
    name: string
    status: typeof statusOptions[number]['value']
    isCustomerAnswering: boolean
    documents: DocumentType[]
    answerItems: CustomerContractType['answerItems']
    contractInvitees: CustomerContractType['contractInvitees']
  }
  refreshCustomerContract: () => void
  customerAnswerPageUrl: string
  customerAnswerPageImageSrc: string
}

export type ConfirmationPropsType = {
  contractSetting?: {
    draftDocuments: DocumentType[]
    questionItems: QuestionItemType[]
  }
  customerContract: {
    readonly id: string
    name: string
    status: typeof statusOptions[number]['value']
    isCustomerAnswering: boolean
    documents: DocumentType[]
    answerItems: CustomerContractType['answerItems']
    contractInvitees: CustomerContractType['contractInvitees']
  }
  refreshCustomerContract: () => void
  customerAnswerPageUrl: string
  customerAnswerPageImageSrc: string
}

export type SignatureCheckPropsType = {
  customerContract: {
    readonly id: string
    name: string
    status: typeof statusOptions[number]['value']
    contractInvitees: CustomerContractType['contractInvitees']
    signedDocuments: DocumentType[]
  }
  currentUserId: string
  contractSettingId?: string
}

export type ContractInviteeType = {
  id: string
  status?: typeof signatureStatusOptions[number]['value']
  inviteeType: 'User' | 'Customer'
  inviteeId: string
  inviteeEmail: string
  inviteeLastName: string
  inviteeFirstName: string
  inviteeLastNameKana: string
  inviteeFirstNameKana: string
  inviteeWeddingDate: string
  documentFields: {
    id: string
    key: string
    documentId: string
    inputType: typeof inputTypeOptions[number]['value']
    isRequired: boolean
    fieldLocations: {
      page: number
      xCoord: number
      yCoord: number
    }[]
  }[]
}

export type CustomerContractType = {
  readonly id: string
  name: string
  status?: typeof statusOptions[number]['value']
  isCustomerAnswering?: boolean
  folder?: {
    id: string
    name: string
  }
  documents?: (DocumentType & { draftDocumentId?: string })[]
  signedDocuments?: DocumentType[]
  answerItems: {
    [questionItemId: string]: {
      value: string | number
      subvalue?: string
      byCustomer?: boolean
    }
  }
  contractInvitees?: ContractInviteeType[]
}

export type CustomerContractFormType = {
  name: string
  status: typeof statusOptions[number]['value']
  folderOption?: {
    value: string
    label: string
  }
  additionalDocuments: File[]
  approvers: {
    value: string
    label: string
  }[]
  customers: {
    id: string
    email: string
    lastName: string
    firstName: string
    lastNameKana: string
    firstNameKana: string
    weddingDate: string
  }[]
  answerItems: {
    [questionItemId: string]: {
      byCustomer?: boolean
      value: string | number
      subvalue?: string
      selectedOption?: {
        value: string
        label: string
      }
      checkedOptions: {
        value: string
        isChecked: boolean
      }[]
    }
  }
  documentFields: {
    id: string
    key: string
    contractInviteeOption: {
      value: string
      label: string
    }
    documentId: string
    inputTypeOption: typeof inputTypeOptions[number]
    isRequired: boolean
    width?: number
    height?: number
  }[]
  fieldLocations: {
    fieldKey: string
    page: number
    xCoord: number
    yCoord: number
  }[]
}

export type CustomerContractSubmitBodyType =
  | FormData
  | {
      customer_contract: {
        name?: string
        contract_setting_id?: string
        folder_id?: string
        approver_forms?: string[]
        customer_forms?: {
          [customer_order: string]: {
            email: string
            last_name: string
            first_name: string
            last_name_kana: string
            first_name_kana: string
            wedding_date: string
          }
        }
        answer_item_forms: {
          question_item_id: string
          value: string
          subvalue?: string
          by_customer?: boolean
        }[]
        document_field_forms?: {
          id: string
          contract_invitee_id: string
          original_document_id: string
          input_type: typeof inputTypeOptions[number]['value']
          is_required: boolean
          width: number
          height: number
          field_location_forms?: {
            page: number
            x_coord: number
            y_coord: number
          }[]
        }[]
      }
    }

export type CustomerContractFormStateReturn = UseFormStateReturn<CustomerContractFormType>

export const statusOptions = [
  { label: '下書き', value: 'draft' },
  { label: '署名回覧中', value: 'pending' },
  { label: '契約成立', value: 'completed' },
  { label: 'キャンセル', value: 'canceled' },
  { label: '否認', value: 'declined' },
  { label: '期限切れ', value: 'expired' },
] as const

export const signatureStatusOptions = [
  { label: '依頼予定', value: 'assigned' },
  { label: '合意依頼中', value: 'pending' },
  { label: '合意', value: 'approved' },
  { label: '否認', value: 'declined' },
  { label: 'キャンセル', value: 'canceled' },
] as const
