import React from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faShare, faCheck, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import {
  isCustomerTypeQuestion,
  isFieldTypeQuestion,
} from '@/components/features/ContractSetting/hooks/useContractSettingForm'

import {
  QuestionItemFormPropsType,
  commonAnswerTypeOptions,
  fieldAnswerTypeOptions,
  answerTypeOptions,
} from '../../types'

export const QuestionItemForm: React.FC<QuestionItemFormPropsType> = (props) => {
  const customerOptions = [...Array(props.customerInviteeNum)].map((_, i) => ({
    value: i,
    label: `クライアント${i + 1}`,
  }))
  const approverOptions =
    props.approverSettings?.map((approver) => ({
      value: approver.id,
      label: `${approver.lastName}${approver.firstName}`,
    })) || []
  const fieldOwnerOptions = [...customerOptions, ...approverOptions]
  const { labelText, sublabelText, textLengthCaution } = (() => {
    const textLength = Math.floor(((props.questionItem.width || 0) - 14) / 5)
    const fullWidthTextLength = Math.floor(((props.questionItem.width || 0) - 14) / 10)
    switch (props.questionItem.answerType) {
      case 'name':
        return { labelText: '姓', sublabelText: '名', textLengthCaution: `※ 約${fullWidthTextLength - 1}文字` }
      case 'name_kana':
        return { labelText: 'セイ', sublabelText: 'メイ', textLengthCaution: `※ 約${fullWidthTextLength - 1}文字` }
      case 'email':
        return { labelText: 'メール', sublabelText: '', textLengthCaution: `※ 約${textLength}文字` }
      case 'phone_number':
        return { labelText: '電話番号', sublabelText: '', textLengthCaution: '' }
      case 'address':
        return { labelText: '〒', sublabelText: '住所', textLengthCaution: '' }
      case 'number':
        return { labelText: '', sublabelText: '', textLengthCaution: `※ 約${textLength - 2}桁` }
      case 'text':
      case 'textarea':
      case 'single_select':
      case 'multi_select':
      case 'text_field':
        return { labelText: '', sublabelText: '', textLengthCaution: `※ 約${fullWidthTextLength}文字` }
      default:
        return { labelText: '', sublabelText: '', textLengthCaution: '' }
    }
  })()
  const answerer = fieldOwnerOptions.find(
    (opt) => opt.value === props.questionItem.customerOrder || opt.value === props.questionItem.approverSettingId,
  )
  const setDefaultDimensions = (answerType: string | number | undefined) => {
    const width = (() => {
      switch (answerType) {
        case 'textarea_field':
        case 'textarea':
        case 'address':
          return 360
        case 'text_field':
        case 'text':
        case 'single_select':
        case 'multi_select':
          return 180
        case 'signature_field':
        case 'signature_date_field':
        case 'date':
        case 'time':
        case 'phone_number':
          return 120
        case 'stamp_field':
        case 'number':
          return 60
        case 'checkbox_field':
          return 12
        default:
          return 120
      }
    })()
    const height = (() => {
      switch (answerType) {
        case 'textarea_field':
        case 'address':
          return 120
        case 'stamp_field':
          return 60
        case 'textarea':
        case 'text':
        case 'single_select':
        case 'multi_select':
        case 'date':
        case 'time':
        case 'phone_number':
        case 'number':
          return 24
        case 'checkbox_field':
          return 12
        default:
          return 24
      }
    })()
    props.setContractSettingValue(`questionItems.${props.index}.width`, width)
    props.setContractSettingValue(`questionItems.${props.index}.height`, height)
  }

  return (
    <Card className="p-2 my-2">
      <Row className="gy-1">
        {(isCustomerTypeQuestion(props.questionItem.answerType) && (
          <>
            {/* <Col xs={3} className="text-nowrap">クライアント</Col> */}
            <Col xs={12}>{answerer?.label}</Col>
          </>
        )) ||
          (isFieldTypeQuestion(props.questionItem.answerType) && (
            <>
              <Form.Label
                column
                xs={3}
                lg={4}
                className="text-nowrap"
                htmlFor={`questionItems.${props.index}.answererOption`}
              >
                記入者
              </Form.Label>
              <Col xs={9} lg={8} className="ps-0">
                <Controller
                  control={props.contractSettingControl}
                  name={`questionItems.${props.index}.answererOption`}
                  render={({ field }) => (
                    <Select
                      options={fieldOwnerOptions}
                      isDisabled={props.disabled}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value)
                        props.setContractSettingValue(`questionItems.${props.index}.label`, value?.label || '')
                      }}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Col>
            </>
          ))}
        {!isFieldTypeQuestion(props.questionItem.answerType) && (
          <Col xs={12}>
            <Form.Check
              id={`questionItems.${props.index}.initiallyByCustomer`}
              type="checkbox"
              {...props.contractSettingRegister(`questionItems.${props.index}.initiallyByCustomer`)}
              label="「送信先で入力」の初期値"
              disabled={props.disabled}
            />
          </Col>
        )}
        <Form.Label column xs={3} lg={4} className="text-nowrap" htmlFor={`questionItems.${props.index}.label`}>
          項目名
        </Form.Label>
        <Col xs={9} lg={8} className="ps-0">
          <Form.Control
            {...props.contractSettingRegister(`questionItems.${props.index}.label` as const, {
              required: true,
            })}
            type="text"
            placeholder={`項目名${
              ['name', 'name_kana', 'email', 'phone_number', 'address'].includes(props.questionItem.answerType)
                ? `(${labelText})`
                : ''
            }を入力してください`}
            required
            disabled={props.disabled}
          />
        </Col>
        {['name', 'name_kana', 'address'].includes(props.questionItem.answerType) && (
          <>
            <Form.Label
              column
              xs={3}
              lg={4}
              className="text-nowrap"
              htmlFor={`questionItems.${props.index}.sublabel`}
            ></Form.Label>
            <Col xs={9} lg={8} className="ps-0">
              <Form.Control
                {...props.contractSettingRegister(`questionItems.${props.index}.sublabel` as const)}
                type="text"
                placeholder={`項目名(${sublabelText})を入力してください`}
                disabled={props.disabled}
              />
            </Col>
          </>
        )}
        {isCustomerTypeQuestion(props.questionItem.answerType) ? (
          <>
            <Col xs={3} lg={4} className="text-nowrap">
              タイプ
            </Col>
            <Col xs={9} lg={8} className="ps-0">
              {answerTypeOptions.find((opt) => opt.value === props.questionItem.answerType)?.label}
            </Col>
          </>
        ) : (
          <>
            <Form.Label
              column
              xs={3}
              lg={4}
              className="text-nowrap"
              htmlFor={`questionItems.${props.index}.answerTypeOption`}
            >
              タイプ
            </Form.Label>
            <Col xs={9} lg={8} className="ps-0">
              <Controller
                control={props.contractSettingControl}
                name={`questionItems.${props.index}.answerTypeOption`}
                render={({ field }) => (
                  <Select
                    options={
                      (isFieldTypeQuestion(props.questionItem.answerType) && fieldAnswerTypeOptions) ||
                      commonAnswerTypeOptions
                    }
                    isDisabled={props.disabled}
                    {...field}
                    onChange={(value) => {
                      field.onChange(value)
                      setDefaultDimensions(value?.value)
                      if (['signature_field', 'signature_date_field', 'stamp_field'].includes(value?.value || '')) {
                        props.setContractSettingValue(`questionItems.${props.index}.isRequired`, true)
                      }
                    }}
                  />
                )}
                rules={{ required: true }}
              />
            </Col>
          </>
        )}
        {props.questionItem.answerType === 'number' && (
          <>
            <Form.Label column xs={3} lg={4} className="text-nowrap" htmlFor={`questionItems.${props.index}.unit`}>
              単位
            </Form.Label>
            <Col xs={9} lg={8} className="ps-0">
              <Form.Control
                {...props.contractSettingRegister(`questionItems.${props.index}.unit` as const)}
                type="text"
                placeholder="単位を入力してください"
                disabled={props.disabled}
              />
            </Col>
          </>
        )}
        {['single_select', 'multi_select'].includes(props.questionItem.answerType) && (
          <>
            <Form.Label column xs={3} lg={4} className="text-nowrap" htmlFor={`questionItems.${props.index}.unit`}>
              選択肢
            </Form.Label>
            <Col xs={9} lg={8} className="ps-0">
              <Controller
                control={props.contractSettingControl}
                name={`questionItems.${props.index}.selectItems`}
                render={({ field }) => (
                  <CreatableSelect
                    options={[]}
                    isMulti
                    noOptionsMessage={() => '選択肢を追加する'}
                    formatCreateLabel={(input) => `『${input}』を追加する(Enter)`}
                    placeholder="選択肢を入力してください"
                    isDisabled={props.disabled}
                    {...field}
                  />
                )}
                rules={{ required: true }}
              />
            </Col>
          </>
        )}
        {['email', 'name', 'signature_field', 'signature_date_field', 'stamp_field'].includes(
          props.questionItem.answerType,
        ) ? (
          <>
            <Col xs={3} lg={4} className="text-nowrap">
              必須
            </Col>
            <Col xs={1} className="ps-0">
              <FontAwesomeIcon
                icon={faCheck}
                className={props.questionItem.isRequired ? 'text-success' : 'text-secondary'}
              />
            </Col>
          </>
        ) : (
          <>
            <Form.Label
              column
              xs={3}
              lg={4}
              className="text-nowrap m-0"
              htmlFor={`questionItems.${props.index}.isRequired`}
            >
              必須
            </Form.Label>
            <Col xs={1} className="my-auto ps-0">
              <Form.Check
                id={`questionItems.${props.index}.isRequired`}
                type="checkbox"
                {...props.contractSettingRegister(`questionItems.${props.index}.isRequired`)}
                disabled={props.disabled}
              />
            </Col>
          </>
        )}
        <Col xs={8} lg={7} className="text-end">
          {props.switchQuestionItems && (
            <>
              <Button
                variant="secondary ms-1"
                size="sm"
                onClick={() => props.switchQuestionItems && props.switchQuestionItems('up')}
                disabled={props.disabled}
              >
                <FontAwesomeIcon icon={faChevronUp} />
              </Button>
              <Button
                variant="secondary ms-1"
                size="sm"
                onClick={() => props.switchQuestionItems && props.switchQuestionItems('down')}
                disabled={props.disabled}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </Button>
            </>
          )}
          {!isCustomerTypeQuestion(props.questionItem.answerType) && props.removeQuestionItem && (
            <Button
              variant="outline-dark ms-1"
              size="sm"
              className="border-0"
              onClick={() => props.removeQuestionItem && props.removeQuestionItem()}
              disabled={props.disabled}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          )}
        </Col>
        {props.appendItemOutputLocation &&
          props.currentDocumentPage?.id &&
          props.currentDocumentPage.id !== 'children' && (
            <>
              <Col xs={12} className="text-end">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() =>
                    props.appendItemOutputLocation &&
                    props.currentDocumentPage &&
                    props.appendItemOutputLocation({
                      itemKey: props.questionItem?.key || props.questionItem.id,
                      documentId: props.currentDocumentPage.id,
                      page: props.currentDocumentPage.page,
                      xCoord: 0,
                      yCoord: 0,
                    })
                  }
                  disabled={props.disabled}
                >
                  PDFに反映
                  <FontAwesomeIcon icon={faShare} className="ms-1" />
                </Button>
              </Col>
              {props.questionItem.outputLocations &&
                props.questionItem.outputLocations?.length > 0 &&
                !['signature_date_field', 'checkbox_field'].includes(props.questionItem.answerType) && (
                  <>
                    <Form.Label
                      column
                      xs={3}
                      lg={4}
                      className="text-nowrap"
                      htmlFor={`questionItems.${props.index}.width`}
                    >
                      {['signature_field', 'textarea_field', 'stamp_field'].includes(props.questionItem.answerType) &&
                        '横'}
                      幅
                    </Form.Label>
                    <Col xs={9} lg={8} className="ps-0">
                      <Form.Control
                        {...props.contractSettingRegister(`questionItems.${props.index}.width` as const)}
                        type="number"
                        disabled={props.disabled}
                      />
                    </Col>
                    {['signature_field', 'textarea_field', 'stamp_field'].includes(props.questionItem.answerType) && (
                      <>
                        <Form.Label
                          column
                          xs={3}
                          lg={4}
                          className="text-nowrap"
                          htmlFor={`questionItems.${props.index}.height`}
                        >
                          縦幅
                        </Form.Label>
                        <Col xs={9} lg={8} className="ps-0">
                          <Form.Control
                            {...props.contractSettingRegister(`questionItems.${props.index}.height` as const)}
                            type="number"
                            disabled={props.disabled}
                          />
                        </Col>
                      </>
                    )}
                    <Col xs={12} className="small">
                      {textLengthCaution}
                    </Col>
                  </>
                )}
              {props.draftDocuments &&
                props.questionItem.outputLocations?.map((loc, i) => (
                  <Col xs={12} key={i} className="small">
                    <span className="mw-100 d-inline-block text-truncate">
                      {props.draftDocuments &&
                        props.draftDocuments[props.draftDocuments.findIndex((doc) => doc.id === loc.documentId)]?.name}
                    </span>
                    <span className="ms-1 d-inline-block text-truncate">
                      (P.{loc.page}, X: {loc.xCoord}, Y: {loc.yCoord})
                    </span>
                  </Col>
                ))}
            </>
          )}
      </Row>
    </Card>
  )
}
