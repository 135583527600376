import { UseFormRegister, Control, UseFieldArrayRemove, UseFieldArrayAppend, UseFormSetValue } from 'react-hook-form'
import { ContractSettingFormType } from '../ContractSetting/types'

export const commonAnswerTypeOptions = [
  { value: 'text', label: '文字列' },
  { value: 'textarea', label: '文章' },
  { value: 'single_select', label: '単一選択' },
  { value: 'multi_select', label: '複数選択' },
  { value: 'date', label: '日付' },
  { value: 'time', label: '時間' },
  { value: 'number', label: '数値' },
] as const
export const customerAnswerTypeOptions = [
  { value: 'email', label: 'メールアドレス' },
  { value: 'name', label: '姓・名' },
  { value: 'name_kana', label: 'セイ・メイ' },
] as const
export const fieldAnswerTypeOptions = [
  { value: 'signature_field', label: '署名' },
  { value: 'signature_date_field', label: '署名日時' },
  { value: 'text_field', label: '文字列' },
  { value: 'textarea_field', label: '文章' },
  { value: 'checkbox_field', label: 'チェックボックス' },
  { value: 'stamp_field', label: '印鑑' },
] as const

export const answerTypeOptions = [
  ...commonAnswerTypeOptions,
  ...customerAnswerTypeOptions,
  ...fieldAnswerTypeOptions,
  { value: 'phone_number', label: '電話番号' },
  { value: 'address', label: '郵便番号・住所' },
] as const

export type QuestionItemType = {
  readonly id: string
  key?: string
  label: string
  sublabel?: string
  answerType: typeof answerTypeOptions[number]['value']
  unit?: string
  selectItemsText?: string
  order: number
  customerOrder?: number
  approverSettingId?: string
  approverName?: string
  isRequired: boolean
  initiallyByCustomer: boolean
  width?: number
  height?: number
  outputLocations?: {
    documentId: string
    page: number
    xCoord: number
    yCoord: number
  }[]
}

export type QuestionItemFormType = Omit<
  QuestionItemType,
  | 'order'
  | 'answerType'
  | 'selectItemsText'
  | 'customerOrder'
  | 'approverSettingId'
  | 'approverName'
  | 'outputLocations'
> & {
  answerTypeOption: typeof answerTypeOptions[number]
  answererOption?: {
    value: string | number
    label: string
  }
  selectItems?: {
    value: string
    label: string
  }[]
}

export type QuestionItemsFormPropsType = {
  questionItems: QuestionItemType[]
  contractSettingRegister: UseFormRegister<ContractSettingFormType>
  contractSettingControl: Control<ContractSettingFormType>
  appendQuestionItem: (answerTypeOption?: typeof answerTypeOptions[number]) => string
  removeQuestionItem: UseFieldArrayRemove
  switchQuestionItems?: (index: number, arrow: 'up' | 'down') => void
  currentDocumentPage?: {
    id: string
    page: number
  }
  draftDocuments?: {
    readonly id: string
    name: string
  }[]
  appendItemOutputLocation: UseFieldArrayAppend<ContractSettingFormType, 'itemOutputLocations'>
  customerInviteeNum: number
  approverSettings?: { id: string; lastName: string; firstName: string }[]
  forInputField?: boolean
  setContractSettingValue: UseFormSetValue<ContractSettingFormType>
  disabled?: boolean
}

export type QuestionItemFormPropsType = {
  index: number
  questionItem: QuestionItemType
  contractSettingRegister: UseFormRegister<ContractSettingFormType>
  contractSettingControl: Control<ContractSettingFormType>
  removeQuestionItem?: UseFieldArrayRemove
  switchQuestionItems?: (arrow: 'up' | 'down') => void
  currentDocumentPage?: {
    id: string
    page: number
  }
  draftDocuments?: {
    readonly id: string
    name: string
  }[]
  appendItemOutputLocation?: UseFieldArrayAppend<ContractSettingFormType, 'itemOutputLocations'>
  customerInviteeNum: number
  approverSettings?: { id: string; lastName: string; firstName: string }[]
  setContractSettingValue: UseFormSetValue<ContractSettingFormType>
  disabled?: boolean
}

export type QuestionItemHiddenFormPropsType = {
  index: number
  questionItem: QuestionItemType
  contractSettingRegister: UseFormRegister<ContractSettingFormType>
  currentDocumentPage?: {
    id: string
    page: number
  }
  draftDocuments?: {
    readonly id: string
    name: string
  }[]
  appendItemOutputLocation?: UseFieldArrayAppend<ContractSettingFormType, 'itemOutputLocations'>
  approverSettings?: { id: string; lastName: string; firstName: string }[]
}

export type PageViewType = {
  width: number
  height: number
}

export type QuestionFormPreviewPropsType = {
  questionItems: Omit<QuestionItemType, 'approverSettingId' | 'approverName' | 'outputLocations'>[]
  contractSettingName?: string
}
